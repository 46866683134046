const { ethers } = require("ethers");
const TronWeb = require('tronweb')

export default {
    async addressCheck (network, address) {
        switch (network) {
            case 'eth':
                return this.ethAddress(address)

            case 'bsc':
                return this.ethAddress(address)

            case 'trx':
                return await this.trxAddress(address)

            default:
                break;
        }
    },
    async ethAddress (address) {
        return await ethers.utils.isAddress(address)
    },

    async trxAddress (address) {
        const tronWeb = new TronWeb({
            fullNode: 'https://api.trongrid.io/',
            solidityNode: 'https://api.trongrid.io/',
            eventServer: 'https://api.trongrid.io/',
        });
        console.log(address)
        console.log(await tronWeb.isAddress(address));
        return await tronWeb.isAddress(address);
    }

}