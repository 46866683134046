<template>
  <div>
    <div class="header-title justify-content-between">
      <b>{{ balance.symbol }} {{ $t("Wallet") }}</b>
    </div>

    <div
      class="d-flex justify-content-center align-items-center h-100"
      v-if="isLoading"
    >
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>

    <div v-else>
      <div class="balance-container p-3">
        <div
          class="col-6 col-lg-3 px-0 d-flex align-items-center justify-content-start"
        >
          <div>
            <img
              :src="balance.token_image"
              alt="savvix-token"
              width="50px"
              height="50px"
            />
          </div>
          <div class="d-flex flex-column ml-3">
            <div>{{ balance.token }}</div>
            <div>{{ balance.symbol }}</div>
          </div>
        </div>

        <div class="col-6 col-lg-9 d-flex flex-wrap px-0">
          <div class="balance-wrapper col-12 col-lg-4 mb-2 mb-lg-0">
            <div>
              {{ $t("Gas Balance") }} (
              {{ chargeUsd ? "USDT" : network.toUpperCase() }} )
            </div>
            <div class="font-xl">
              {{
                balance.gas?.gas_balance == null ? 0 : balance.gas?.gas_balance
              }}
            </div>
          </div>

          <div class="balance-wrapper col-12 col-lg-4 mb-2 mb-lg-0">
            <div>{{ $t("Floating") }}</div>
            <div class="font-xl">
              {{
                balance.floating_balance == null
                  ? 0
                  : formatNum(
                      balance.floating_balance,
                      balance.token_volume_decimal
                    )
              }}
            </div>
          </div>

          <div class="balance-wrapper col-12 col-lg-4 mb-2 mb-lg-0">
            <div>{{ $t("Withdrawable") }}</div>
            <div class="font-xl">
              {{
                balance.withdrawable == null
                  ? 0
                  : formatNum(
                      balance.withdrawable,
                      balance.token_volume_decimal
                    )
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="my-3">
          <div class="d-flex flex-wrap justify-content-center">
            <div class="col-6 col-md-6 col-lg-3 mb-2">
              <b-button class="info-outline-btn" @click="showSendModal">
                <inline-svg
                  :src="require('../assets/icon/new-icon/send.svg')"
                  width="20px"
                  height="20px"
                ></inline-svg>
                <span class="ml-2">{{ $t("Send") }}</span>
              </b-button>
            </div>

            <div
              v-if="userType != 'reuse'"
              class="col-6 col-md-6 col-lg-3 mb-2"
            >
              <b-button class="info-outline-btn" @click="showReceiveModal">
                <inline-svg
                  :src="require('../assets/icon/new-icon/receive.svg')"
                  width="20px"
                  height="20px"
                ></inline-svg>
                <span class="ml-2">{{ $t("Receive") }}</span>
              </b-button>
            </div>

            <div v-else class="col-6 col-md-6 col-lg-3 mb-2">
              <b-button
                class="info-outline-btn"
                @click="showRequestDepositModal"
              >
                <inline-svg
                  :src="require('../assets/icon/new-icon/receive.svg')"
                  width="20px"
                  height="20px"
                ></inline-svg>
                <span class="ml-2">{{ $t("Request Deposit") }}</span>
              </b-button>
            </div>

            <div class="col-6 col-md-6 col-lg-3 mb-2">
              <b-button class="info-outline-btn" @click="showGasModal">
                <inline-svg
                  :src="require('../assets/icon/new-icon/refill-gas.svg')"
                  width="20px"
                  height="20px"
                ></inline-svg>
                <span class="ml-2">{{ $t("Refill Gas") }}</span>
              </b-button>
            </div>

            <div class="col-6 col-md-6 col-lg-3 mb-2">
              <b-button class="info-outline-btn" @click="routeToTransaction">
                <inline-svg
                  :src="require('../assets/icon/new-icon/transaction-blue.svg')"
                  width="20px"
                  height="20px"
                ></inline-svg>
                <span class="ml-2">{{ $t("Transaction") }}</span>
              </b-button>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="d-flex justify-content-between">
            <div class="col-3 col-lg-3">
              <form>
                <b-button
                  v-if="userType != 'reuse'"
                  class="btn-primary d-flex align-items-center"
                  @click="showAddChildModal"
                >
                  <span class="mr-2 d-none d-lg-block">{{
                    $t("Add Child Address")
                  }}</span>
                  <inline-svg
                    :src="require('../assets/icon/new-icon/add-circle.svg')"
                    width="24px"
                    height="24px"
                    aria-label="logout"
                  ></inline-svg>
                </b-button>
              </form>
            </div>

            <div class="col-9 col-lg-3">
              <form @submit.prevent="fetchAddress" class="search-input">
                <input
                  class="form-control"
                  type="text"
                  :placeholder="$t(`Search label`)"
                  v-model="address"
                />
                <b-icon
                  type="button"
                  class="mr-2"
                  icon="search"
                  @click.prevent="fetchAddress"
                ></b-icon>
              </form>
            </div>
          </div>
          <div class="table-responsive">
            <table
              class="table table-small table-borderless my-2"
              id="child-add-table"
            >
              <thead>
                <th class="text-center" width="5%">{{ $t("No.") }}</th>
                <th width="70%">{{ $t("ADDRESS") }}</th>
                <th>{{ $t("AMOUNT") }}</th>
                <th v-if="userType == 'reuse'">{{ $t("STATUS") }}</th>
                <th class="text-left">{{ $t("LABEL") }}</th>
              </thead>

              <tbody v-if="token_wallet.length == 0">
                <tr>
                  <td
                    class="text-center"
                    :colspan="userType == 'reuse' ? 4 : 3"
                  >
                    {{ $t("No Data") }}
                  </td>
                </tr>
              </tbody>

              <tbody v-for="(row, index) in token_wallet" v-bind:key="index">
                <tr>
                  <td class="text-center">
                    {{ index + 1 + (currentPage - 1) * 10 }}
                  </td>
                  <td>
                    <div class="d-flex flex-row align-items-center text-left">
                      <div class="address">
                        <span class="desktop-address d-none d-lg-block">{{
                          row.address
                        }}</span>
                        <span class="mobile-address d-block d-lg-none">
                          {{ row.address.substr(0, 5) }} ...
                          {{ row.address.substr(-5, 5) }}
                        </span>
                      </div>
                      <div class="tooltip__layout ml-2">
                        <button
                          class="icon-btn"
                          @click="
                            copyToClip(row.address, `child` + row.address)
                          "
                        >
                          <inline-svg
                            :src="require('../assets/icon/copy.svg')"
                            width="18"
                            aria-label="copy"
                          ></inline-svg>
                          <span
                            class="tooltip__content"
                            :id="'child' + row.address"
                            >{{ $t("Copied!") }}</span
                          >
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{
                      row.amount
                        .toFixed(token_one.token?.volume_decimal)
                        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                    }}
                  </td>

                  <td v-if="userType == 'reuse'" :class="status[row.status]">
                    {{ row.status }}
                  </td>
                  <td class="text-left">{{ row.label }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="addressList"
              align="center"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="balance container">
      <!-- Send Modal -->
      <b-modal
        ref="send-modal"
        hide-footer
        :title="'SEND ' + balance.symbol"
        centered
      >
        <div class="d-flex flex-column text-center">
          <form
            id="send-modal-form"
            class="modal-form"
            @submit.stop.prevent="sendToken"
          >
            <div class="form-group">
              <b-form-input
                id="example-input-1"
                :placeholder="$t(`Enter to address`)"
                v-model="$v.sendTokenForm.to_address.$model"
                :state="validateState('to_address', 'sendTokenForm')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This is a required field.")
              }}</b-form-invalid-feedback>
            </div>

            <div class="form-group">
              <div class="form-group">
                <b-form-input
                  id="example-input-1"
                  :placeholder="$t(`Enter amount`)"
                  v-model="$v.sendTokenForm.amount.$model"
                  :state="validateState('amount', 'sendTokenForm')"
                  aria-describedby="input-1-live-feedback"
                  type="number"
                  step="0.0001"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This is a required field.")
                }}</b-form-invalid-feedback>
              </div>
            </div>

            <div
              class="form-group d-flex flex-column justify-content-center align-items-center"
            >
              <p class="mb-3">{{ $t("2FA") }}</p>
              <!-- <PincodeInput
                                v-model="sendTokenForm.twofa"
                                :length=6
                            /> -->
              <v-otp-input
                ref="sendOtpInput"
                input-classes="otp-input"
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                input-type="number"
                @on-complete="onSendTwoFaComplete"
              />
            </div>

            <b-form-checkbox
              class="mt-4"
              v-model="sendTokenForm.chargeOnUser"
              :value="true"
              :unchecked-value="false"
            >
              Charge On User
            </b-form-checkbox>

            <div class="form-group">
              <div class="mt-5 mb-5 w-100">
                <button
                  type="submit"
                  class="btn active-outline-btn"
                  :disabled="
                    $v.sendTokenForm.amount.$model > balance.withdrawable * 1
                  "
                >
                  {{
                    $v.sendTokenForm.amount.$model > balance.withdrawable * 1
                      ? "Insufficient funds"
                      : "Send"
                  }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </b-modal>

      <!-- Receive Modal -->
      <b-modal
        id="receive-modal"
        ref="receive-modal"
        hide-footer
        :title="$t(`RECEIVE`) + ' ' + balance.symbol"
        centered
      >
        <div class="d-flex flex-column text-center">
          <div class="d-flex flex-column align-items-center">
            <!-- QR -->
            <div class="qr-code">
              <vue-qr :text="receiveModal_address" :size="200"></vue-qr>
            </div>

            <div class="modal-wallet-address">
              <span class="wallet-address pr-3">
                {{ receiveModal_address }}
              </span>

              <div ref="receiveModalBody" class="tooltip__layout">
                <button
                  class="icon-btn"
                  @click="
                    copyToClip(
                      receiveModal_address,
                      'receiveModalAddress',
                      'receiveModalBody'
                    )
                  "
                >
                  <inline-svg
                    :src="require('../assets/icon/copy.svg')"
                    width="18"
                    aria-label="copy"
                  ></inline-svg>
                </button>
                <span class="tooltip__content" id="receiveModalAddress">{{
                  $t("Copied!")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- Gas Modal -->
      <b-modal
        ref="gas-modal"
        hide-footer
        :title="$t(`Gas`) + ' ' + (chargeUsd ? 'USDT' : network.toUpperCase())"
        centered
      >
        <div class="d-flex flex-column text-center">
          <div class="d-flex flex-column align-items-center">
            <!-- QR -->
            <div class="qr-code">
              <vue-qr :text="gasModal_address" :size="200"></vue-qr>
            </div>

            <div class="modal-wallet-address">
              <span class="wallet-address pr-3">
                {{ gasModal_address }}
              </span>

              <div ref="gasModalBody" class="tooltip__layout">
                <button
                  class="icon-btn"
                  @click="
                    copyToClip(
                      gasModal_address,
                      `gas` + gasModal_address,
                      'gasModalBody'
                    )
                  "
                >
                  <inline-svg
                    :src="require('../assets/icon/copy.svg')"
                    width="18"
                    aria-label="copy"
                  ></inline-svg>
                  <span
                    class="tooltip__content"
                    :id="`gas` + gasModal_address"
                    >{{ $t("Copied!") }}</span
                  >
                </button>
              </div>
            </div>

            <!-- <span>Wallet Address</span> -->

            <!-- <div class="mt-4 mb-2 w-100">
                        <button type="submit" class="submit-btn">Share</button>
                    </div> -->
          </div>
        </div>
      </b-modal>

      <!-- Add Child Address Modal -->
      <b-modal
        ref="add-child-modal"
        hide-footer
        :title="$t(`ADD CHILD ADDRESS`)"
        centered
      >
        <div class="d-flex flex-column text-center">
          <form
            id="add-child-form"
            class="modal-form"
            @submit.stop.prevent="addChildAddress"
          >
            <div class="form-group">
              <b-form-input
                id="example-input-1"
                :placeholder="$t(`Enter child address's label`)"
                v-model="$v.addChildForm.label.$model"
                :state="validateState('label', 'addChildForm')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This is a requiredfield.")
              }}</b-form-invalid-feedback>
            </div>

            <div class="form-group">
              <div class="mt-5 mb-5 w-100">
                <button type="submit" class="submit-btn">
                  {{ $t("Add Child Address") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </b-modal>

      <!-- Request Deposit Modal -->
      <b-modal
        ref="request-deposit-modal"
        hide-footer
        :title="$t(`REQUEST DEPOSIT`) + ' ' + balance.symbol"
        centered
      >
        <div class="d-flex flex-column text-center">
          <form
            id="request-deposit-form"
            class="modal-form"
            @submit.stop.prevent="requestDeposit"
          >
            <div class="form-group">
              <b-form-input
                id="example-input-1"
                :placeholder="$t(`Enter amount`)"
                v-model="$v.requestDepositForm.amount.$model"
                :state="validateState('amount', 'requestDepositForm')"
                aria-describedby="input-1-live-feedback"
                type="number"
                :step="1 / Math.pow(10, tokenDecimal)"
              ></b-form-input>
              <small
                >{{ $t("Min amount") }} : {{ 1 / Math.pow(10, tokenDecimal) }}
                {{ balance.symbol }}</small
              >
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This is a required field.")
              }}</b-form-invalid-feedback>
            </div>

            <div class="form-group">
              <div class="mt-5 mb-5 w-100">
                <button type="submit" class="submit-btn">
                  {{ $t("Request") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </b-modal>

      <!-- Setup 2FA Modal -->
      <b-modal
        ref="setup-twofa-modal"
        hide-footer
        :title="$t(`SET UP 2FA`)"
        centered
      >
        <div
          class="d-flex justify-content-center align-items-center h-100"
          v-if="is2FALoading"
        >
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </div>
        <div class="qr-code d-flex flex-column align-items-center w-100" v-else>
          <vue-qr :text="google2faLink" :size="200"></vue-qr>
          <div ref="setUptwoFaModal">
            <span>{{ google2faSecretKey }}</span>
            <div class="tooltip__layout ml-2">
              <button
                type="button"
                class="icon-btn"
                @click="
                  copyToClip(
                    google2faSecretKey,
                    'twoFaSecret',
                    'setUptwoFaModal'
                  )
                "
              >
                <inline-svg
                  :src="require('../assets/icon/copy.svg')"
                  width="18"
                  aria-label="copy"
                ></inline-svg>
                <span class="tooltip__content" id="twoFaSecret">{{
                  $t("Copied!")
                }}</span>
              </button>
            </div>
          </div>

          <form class="mt-3 text-center" @submit.prevent="bind2FA">
            <v-otp-input
              ref="withdrawSetUp2FA"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              nput-type="number"
              @on-complete="withdrawSetUp2FAComplete"
            />

            <button type="submit" class="activate-btn mt-3 w-75">
              {{ $t("Bind 2FA") }}
            </button>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import api from "../helper/api.js";
import addressCheck from "../helper/addressCheck.js";

import InlineSvg from "vue-inline-svg";
import VueQr from "vue-qr";
import coinHelper from "../helper/coin.js";

export default {
  name: "Balance",
  components: {
    InlineSvg,
    VueQr,
  },
  data() {
    return {
      chargeUsd: localStorage.getItem("chargeUsd") == "true",
      google2faSecretKey: null,
      google2faLink: null,
      twoFaCode: null,
      is2FALoading: false,
      isLoading: false,
      network: this.$route.params.network.toLocaleLowerCase(),
      symbol: this.$route.params.symbol,
      userType: localStorage.getItem("userType"),
      addChildForm: {
        label: "",
      },
      token_wallet: [],
      sendTokenForm: {
        to_address: "",
        amount: "",
        twofa: "",
        chargeOnUser: false,
      },
      requestDepositForm: {
        amount: "",
      },
      balance: {
        balance: null,
        nettworth: null,
        token: null,
        token_image: null,
        withdrawable: null,
        floating_balance: null,
        gas: null,
        token_volume_decimal: null,
        symbol: null,
      },
      receiveModal_address: null,
      gasModal_address: null,
      tokenDecimal: 0,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      address: null,
      status: {
        active: "text-success",
        busy: "text-warning",
      },
      token_one: [],
    };
  },
  validations: {
    addChildForm: {
      label: {
        required,
      },
    },
    sendTokenForm: {
      to_address: {
        required,
      },
      amount: {
        required,
        Number,
      },
    },
    requestDepositForm: {
      amount: {
        required,
      },
    },
  },
  watch: {
    currentPage: {
      async handler() {
        this.isLoading = true;
        await this.fetchAddress();
      },
      deep: true,
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchAddress();
    await this.fetchBalances();
    await this.getToken(this.network, this.symbol);
    this.isLoading = false;
  },

  methods: {
    validateState(name, form) {
      const { $dirty, $error } = this.$v[form][name];
      return $dirty ? !$error : null;
    },

    async copyToClip(address, id, ref = null) {
      let refElement = this.$refs[ref];

      await this.$copyText(address, refElement).then(function () {
        var x = document.getElementById(id);
        x.classList.add("show");
        setTimeout(function () {
          x.classList.remove("show");
        }, 1000);
      });
    },

    routeToTransaction() {
      this.$router.push("/transaction");
    },

    addCommas(nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },

    formatNum(num, decimal) {
      return coinHelper.formatNumberWithCommas(num, decimal);
    },

    async addChildAddress() {
      this.$v.addChildForm.$touch();
      if (this.$v.addChildForm.$anyError) {
        return;
      }

      const params = {
        api_symbol: this.$route.params.symbol,
        label: [this.addChildForm.label],
      };

      try {
        const response = await api.apiCall(
          this.network,
          "POST",
          "/addresses/create",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: this.$t("Child address added successfully"),
            icon: "success",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          this.$swal({
            text: this.$t("Child address already added"),
            icon: "error",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$refs["add-child-modal"].hide();
            }
          });
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
      }
    },

    async getToken(network, api_symbol) {
      try {
        const response = await api.apiCall(
          network,
          "GET",
          `/token-globals/get-one/${api_symbol}`
        );
        this.token_one = response.message.data;
        //   this.updateThresholdForm = {
        //     api_symbol: this.token_one.token.api_symbol,
        //     threshold: this.formatNumber(
        //       this.token_one.threshold,
        //       this.token_one.token.decimal
        //     ).toFixed(this.token_one.token.volume_decimal),
        //   };
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    onSendTwoFaComplete(value) {
      this.sendTokenForm.twofa = value;
    },

    async sendToken() {
      this.$v.sendTokenForm.$touch();

      if (this.$v.sendTokenForm.$anyError) {
        return;
      }

      if (
        !(await addressCheck.addressCheck(
          this.network,
          this.sendTokenForm.to_address
        ))
      ) {
        this.$swal("Error", "Address format wrong", "error");
      }

      if (!this.sendTokenForm.twofa) {
        this.$swal("Error", "2FA is required", "error");
      }

      const random = Math.random().toString(36).substring(2, 7);

      const params = {
        to: this.sendTokenForm.to_address,
        api_symbol: this.symbol,
        amount: parseFloat(this.sendTokenForm.amount),
        request_id: "web_" + random + Date.now(),
        google2fa: this.sendTokenForm.twofa,
        charge_on_usr: this.sendTokenForm.chargeOnUser,
      };

      try {
        const response = await api.apiCall(
          this.network,
          "POST",
          "/withdrawals/create",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$refs["send-modal"].hide();
          this.sendTokenForm.to_address = "";
          this.sendTokenForm.amount = "";
          this.sendTokenForm.twofa = "";
          this.$refs.sendOtpInput.clearInput();
          this.$swal({
            text: this.$t("Withdrawal request received"),
            icon: "success",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$route.push("/transaction");
            }
          });
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
        this.$refs.sendOtpInput.clearInput();
      }
    },

    showSendModal() {
      let google2fa = localStorage.getItem("google2fa");
      if (google2fa == "null") {
        this.is2FALoading = true;

        this.get2FACode();

        this.$refs["setup-twofa-modal"].show();
        return;
      }

      this.$refs["send-modal"].show();
    },

    showReceiveModal() {
      this.$refs["receive-modal"].show();
      // let wallet = this.token_wallet.filter(item => item.label == 'default');
      this.receiveModal_address = this.token_wallet[0].address;
    },

    showAddChildModal() {
      this.$refs["add-child-modal"].show();
    },

    showRequestDepositModal() {
      this.$refs["request-deposit-modal"].show();
    },

    showGasModal() {
      this.$refs["gas-modal"].show();
      this.gasModal_address = this.balance.gas.address;
    },

    withdrawSetUp2FAComplete(value) {
      this.twoFaCode = value;
    },

    async get2FACode() {
      try {
        const email = localStorage.getItem("userEmail");
        const platform_name = localStorage.getItem("platform_name");
        const response = await api.apiCall("core", "GET", "/operators/secret");
        this.google2faSecretKey = response.message.secret;
        this.google2faLink = `otpauth://totp/savvixpay:${email}?secret=${this.google2faSecretKey}&issuer=${platform_name}`;
        this.is2FALoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchAddress() {
      try {
        let params = {
          order: "ASC",
          page: this.currentPage,
          take: this.perPage,
          label: this.address,
        };
        const response = await api.apiCall(
          this.network,
          "GET",
          `/token-wallets/get-address/${this.symbol}`,
          params
        );
        this.token_wallet = response.message.data;
        this.totalRows = response.message.meta.itemCount;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchBalances() {
      try {
        const response = await api.apiCall(
          "core",
          "GET",
          `/deposit-summary/single`,
          {
            api_symbol: this.symbol,
            network: this.network,
          }
        );

        this.balance = {
          floating_balance: response.message.floating_balance,
          balance: response.message.balance,
          nettworth: response.message.networth,
          token: response.message.token,
          token_image: response.message.token_image,
          withdrawable: response.message.withdrawable,
          gas: response.message.gas,
          token_volume_decimal: response.message.token_volume_decimal,
          symbol: response.message.symbol,
        };
        this.tokenDecimal = response.message.token_volume_decimal;
      } catch (error) {
        console.log(error);
      }
    },

    async requestDeposit() {
      this.$v.requestDepositForm.$touch();
      if (this.$v.requestDepositForm.$anyError) {
        return;
      }

      if (this.requestDepositForm.amount <= 0) {
        this.$swal({
          text: this.$t("Amount cannot less than 0"),
          icon: "error",
          // confirmButtonColor: '#33AD4A',
        });
        return;
      }

      // const random = Math.random().toString(36).substring(2,7);

      const params = {
        api_symbol: this.symbol,
        amount: parseFloat(this.requestDepositForm.amount),
        deposit_id: "web_" + this.symbol + Date.now(),
      };

      try {
        const response = await api.apiCall(
          this.network,
          "POST",
          "/deposit-request/create-request",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: this.$t("Deposit request successfully"),
            icon: "success",
            confirmButtonColor: "#33AD4A",
          });

          this.$refs["request-deposit-modal"].hide();
          this.requestDepositForm.amount = "";
          // this.fetchRequestDeposits();
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
      }
    },

    async bind2FA() {
      console.log("submitted");
      let params = {
        secret: this.google2faSecretKey,
        google2fa: this.twoFaCode,
      };
      try {
        await api.apiCall("core", "POST", "/operators/bind2fa", params);
        // console.log(response);
        this.$swal({
          text: this.$t("Bind 2FA Success"),
          icon: "success",
          confirmButtonColor: "#33AD4A",
        });

        localStorage.setItem("google2fa", "true");
        window.location.reload();
      } catch (error) {
        console.error(error);
        this.$swal({
          text: error.message,
          icon: "error",
          // confirmButtonColor: '#33AD4A',
        });
      }
    },
  },
};
</script>

<style>
@import "../css/styles.css";

.balance .address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: unset !important;
}

.balance-body {
  margin: 0% 17%;
  height: 100%;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.balance-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.balance-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.top-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin: 0% 10%; */
  margin-top: 2rem;
}

.bal-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.total-balance {
  color: var(--info);
  font-size: 38px;
}

.balance-token-text1 {
  font-size: 18px;
}

.balance-token-text2 {
  font-size: 13px;
}

.bal-btn {
  background-color: transparent;
  /* min-width: 100px; */
  width: 20%;
  border-radius: 30px;
  border: 2px solid var(--primary);
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
  /* margin-left: 2rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
}

.bal-btn:hover,
.bal-btn:active,
.bal-btn:focus {
  color: var(--primary) !important;
  fill: var(--primary) !important;
  border: 2px solid var(--primary) !important;
  background-color: var(--primary-10) !important;
  /* box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px; */
}

.bal-btn #swap {
  fill: var(--primary);
  padding-left: 0.8rem;
}

.bal-btn div {
  padding-left: 1rem;
}

.bal-btn img {
  width: 30px;
}

/* Child Address Table */
.add-child-btn {
  background-color: var(--primary);
  /* border: none; */
  border-radius: 20px;
  padding: 0px 20px 0 40px;
  /* width: 100px; */
  color: var(--light);
  border: 1px solid var(--primary);
  height: 25px;
  /* padding-left: 45px; */
  position: relative;
  min-width: fit-content;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.add-child-btn:before {
  content: "";
  display: block;
  background-color: var(--primary);
  border-radius: 100%;
  width: 35px;
  height: 35px;
  left: -1px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
  bottom: -6px;
  position: absolute;
}

#plus-icon {
  position: absolute;
  left: 6px;
  top: -1px;
  color: white;
}

.add-child-btn:hover,
.add-child-btn:focus,
.add-child-btn:hover text {
  color: var(--primary);
  background-color: var(--light);
  fill: var(--light);
  border: 1px solid var(--primary);
}

/* .search-input {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
}

.search-input input {
    border-radius: 20px;
    padding: 0px 15px;
    height: 25px;
}

.search-input:after {
    content: "";
    display: none;
    background-color: white;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
    bottom: -6px;
    right: 0;
    position: absolute;
} */

.search-icon {
  position: absolute;
  z-index: 2;
  right: 7px;
}

#child-add-table tbody::before {
  content: "";
  display: block;
  height: 10px;
}

#child-add-table th {
  font-size: 14px;
  text-align: left;
}

#child-add-table tr {
  background-color: var(--light);
  color: black;
  text-align: center;
  border-radius: 30px;
}

#child-add-table td {
  vertical-align: middle;
  text-align: left;
}

/* #child-add-table td:first-child {
    border-radius: 30px 0 0 30px;
}

#child-add-table td:last-child {
    border-radius: 0 30px 30px 0;
} */

.child-table-th {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* #add-child-form input {
    background-color: var(--info-10);
    border: none;
    color: var(--info);
    text-align: center;
    border-radius: 30px;
    width: 100%;
} */

.icon-btn {
  padding: 0px;
}

.balance-token {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35%;
}

.balance-withdrawal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.deposit-balance {
  display: flex;
  flex-direction: row;
  width: 65%;
  justify-content: space-between;
}

.deposit-balance > div {
  width: 45%;
}

.balance-title {
  font-size: 1rem;
  /* font-weight: bold; */
  color: var(--primary);
  text-align: center;
  display: flex;
  justify-content: center;
}

.balance-content {
  font-size: var(--xl);
  text-align: center;
  color: var(--info);
}

/* Send Modal */
fieldset {
  color: #555;
  font-family: sans-serif;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

fieldset::after {
  content: "___  ___  ___  ___  ___  ___";
  display: block;
  position: absolute;
  top: 35px;
  white-space: pre;
  color: white;
}

input#tradingPin {
  position: relative;
  font-size: 16px;
  z-index: 2;
  border: none;
  background: transparent;
  width: 300px;
  text-indent: 27px;
  letter-spacing: 25.6px;
  font-family: Courier;
  margin-left: 55px;
  text-align: left;
}

input#tradingPin:focus {
  outline: none;
}

/* Receive Modal */
#receive-content {
  background: transparent;
  border: none;
}

#receive-content .card-header {
  border-bottom: 1px solid white;
  border-top: 2px solid white;
  background: transparent;
  padding: 0rem 0rem 0.75rem 0rem;
  overflow: hidden;
  overflow-x: scroll;
}

#receive-content .nav {
  flex-wrap: nowrap;
}

#receive-content .nav-item {
  padding-top: 2px;
  margin-left: 5px;
}

#receive-content .nav-item a {
  width: 150px;
  text-align: center;
  border: none;
  color: #bbbbbb;
}

#receive-content .nav-item a:hover {
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}

#receive-content .nav-item .active {
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
  background: transparent;
}

.modal-wallet-address {
  color: #707070;
  /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
  padding-bottom: 2rem;
}

.modal-wallet-address .wallet-address {
  word-break: break-all;
}

.mobile-bal-button-group {
  margin-top: 20px;
}

.mobile-bal-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  border: none;
}

.mobile-bal-button .mobile-bal-icon {
  border: 1px solid var(--primary);
  padding: 10px;
  border-radius: 50%;
}

.withdraw {
  color: var(--danger);
}

/* .table-responsive {
    display: inline-table;
} */

.add-child-btn {
  font-size: var(--sm);
}

#plus-icon {
  left: 8px;
  top: 2px;
}

.address-container {
  background-color: var(--light);
  margin-top: 10px;
  padding: 10px 0;
}

#addressList .label {
  word-break: break-all;
}

.balance-container {
  background-color: var(--info);
  color: var(--light);
  display: flex;
}

.balance-container .balance-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.balance .search-input {
  background-color: var(--light-gray);
}

.info-outline-btn {
  stroke: var(--info);
}

.search-input {
  background-color: var(--light-gray);
  border-radius: 50px;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-input input {
  background-color: var(--light-gray);
  border: none;
  padding-left: 20px;
  border-radius: 20px 0 0 20px;
}

.search-input input:focus-visible {
  outline: none;
  background-color: transparent;
  box-shadow: none;
}

@media only screen and (max-width: 768px) {
  .balance-token {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .balance-withdrawal {
    flex-direction: column;
  }

  .deposit-balance {
    width: 100%;
  }

  /* 
    .table-responsive {
        display: block;
    } */

  .balance-body {
    margin: 5%;
  }

  .total-balance {
    font-size: 30px;
  }

  .search-input {
    margin-left: 1rem;
  }

  .bal-button {
    flex-direction: column;
  }

  .bal-btn {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1025px) {
  .add-child-btn {
    font-size: var(--md);
  }

  #plus-icon {
    left: 6px;
    top: -1px;
  }

  /* .search-input:after {
        content: "";
        display: block;
        background-color: white;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 31%);
        bottom: -6px;
        right: 0;
        position: absolute;
    } */
}
</style>
